import request from '@/utils/request';

const userApi = {
  instructList: '/api/instruct_total',
  // instructListShift: '/api/instruct_shift', //新增班次查询方式
};

// 指令-指令查询
export function reqInstructList(parameter) {
  return request({
    url: userApi.instructList,
    method: 'post',
    data: parameter,
  });
}

// // 指令-指令查询：指定班次
// export function reqInstructShiftList(parameter) {
//   return request({
//     url: userApi.instructListShift,
//     method: 'post',
//     data: parameter,
//   });
// }
